<template>
  <div class="wrap-gmap-mark-label">
    <GmapMap
      ref="map"
      :center="center"
      :zoom="zoom"
      :options="getMapOptions()"
      style="width: 100%; height: 100%"
    >
      <!-- <GmapMarker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
        :clickable="true"
        :draggable="false"
        @click="center = m.position"
        :icon="getIcon(m.id)"
        :label="m.label"
      /> -->
      <GmapMarkerWithLabel
        v-for="(event, index) in eventList"
        :key="event.id"
        :position="getPosition(event)"
        :icon="getIcon(event, index)"
        :zIndex="getZIndex(index)"
        label-class="history-marker-label"
        :label-anchor="getAnchor()"
        :label-in-background="true"
        :label-visible="true"
        :clickable="true"
        :draggable="false"
        @click="setFocusEventIndex(index)"
        @mouseover="onMouseOver(index)"
        @mouseout="onMouseOut(index)"
      >
        <template v-if="index === focusIndex || index === hoverIndex" #labelContent>
          <div class="event-map-content">
            <div class="info">
              <div class="event-map-colck-icon">
                <img src="@/assets/icons/clock-solid.svg" alt="">
              </div>
              {{ getDetectTime(event) }}
            </div>
          </div>
        </template>
        <template v-else #labelContent>
          <div></div>
        </template>
      </GmapMarkerWithLabel>
      <gmap-polyline v-if="videoId" :path.sync="path" :options="getPathOption()" />
    </GmapMap>
  </div>  
</template>

<script>
import GmapMarkerWithLabel from '@/components/Btm/MarkerLabel.vue'
import { apiGetVideoGPS } from '@/api/index.js'
import { mapMutations, mapState } from 'vuex'
import { formatTime } from '@/utils/lib.js'

export default {
  name: 'GmapMarkerPath',
  components: { GmapMarkerWithLabel },
  props: ['eventList', 'focusIndex', 'videoId'],
  data() {
    return {
      center: { lat: 24.807824129248665, lng: 121.03832092621745 },
      zoom: 15,
      mapStyle: { width: '100%', height: '100%' },
      path: [],
      hoverIndex: -1,
    }
  },
  computed: {
    ...mapState('history', ['historyCurrentPage']),
  },
  watch: {
    focusIndex() {
      this.panToFocusEvent()
    },
    historyCurrentPage() {
      this.panToFocusEvent()
    },
    'eventList.length'() {
      this.zoomExtends()
    }
  },
  mounted() {
    if (this.videoId) this.getVideoGPS(this.videoId)
    this.$refs.map.$mapPromise.then(() => {
      this.zoomExtends()

      // 重整時倍率會抓到一開始設定的15而無法修正，所以加上timeout
      setTimeout(() => {
        // 當marker距離很接近時，放大倍率大增，需要再調整成zoom=15
        if (this.$refs.map.$mapObject.getZoom() > 15) {
          this.$refs.map.$mapObject.setZoom(15)
        }
      }, 100)
    })
  },
  methods: {
    ...mapMutations('history', ['updateFocusEventIndex']),
    async getVideoGPS(videoId) {
      try {
        this.path = []
        let res = await apiGetVideoGPS(videoId)
        res.data.gpsList.forEach(item => {
          this.path.push({
            lat: item.latitude,
            lng: item.longitude
          })
        })
        res = null
      } catch (err) {
        console.log('getVideoGPS error:', err)
      }
    },
    getAnchor() {
      return { x: 0, y: 80 }
    },
    getMapOptions() {
      return {
        mapTypeId: 'terrain',
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUI: false,
        styles: [
          {
            featureType: 'poi.business',
            stylers: [{ visibility: 'off' }]
          }
        ]
      }
    },
    getPosition(event) {
      return { lat: event.latitude, lng: event.longitude }
    },
    getDetectTime(event) {
      return formatTime(event.detectTime)
    },
    getIcon(event, index) {
      let url
      if (this.getChasingStatus(event)) {
        url = (index === this.focusIndex || index === this.hoverIndex) ?
          require('@/assets/icons/mark-red-hover.svg') : require('@/assets/icons/mark-red.svg')
      } else {
        url = (index === this.focusIndex || index === this.hoverIndex) ? 
          require('@/assets/icons/mark-yellow-hover.svg') : require('@/assets/icons/mark-yellow.svg')
      }
        
      return {
        url: url,
        // scaledSize: { height: 44, width: 31, f: 'px', b: 'px' }
      }
    },
    getZIndex(index) {
      // 若有hover在某個marker，設定其z-index大於focus那個
      return (this.hoverIndex >= 0 && index === this.hoverIndex) ? this.eventList.length + 2 : (index === this.focusIndex) ? this.eventList.length + 1 : index
    },
    setFocusEventIndex(index) {
      this.updateFocusEventIndex(index)
    },
    getContent(marker) {
      return `<div style="font-size:14px; line-height:16px">${marker.name}</div><span style="font-size:12px; line-height:14px">(${marker.id})<span>`
    },
    getPathOption() {
      return { strokeColor: '#FE6847' }
    },
    getChasingStatus(event) {
      return event.chasing == 1 ||
        (event.chasingStartTime && event.chasingEndTime)
        ? true
        : false
    },
    zoomExtends() {
      if (this.eventList.length > 1) {
        let bounds = new window.google.maps.LatLngBounds()
        this.eventList.forEach((event) => {
          let position = {
            lat: event.latitude,
            lng: event.longitude
          }
          bounds.extend(position)
        })
        this.$refs.map.fitBounds(bounds)

        // 當marker距離很接近時，放大倍率大增，需要再調整成zoom=15
        if (this.$refs.map.$mapObject.getZoom() > 20) {
          this.$refs.map.$mapObject.setZoom(20)
        }
        bounds = null
      } else {
        if (this.eventList.length === 1) {
          let position = {
            lat: this.eventList[0].latitude,
            lng: this.eventList[0].longitude
          }
          this.$refs.map.$mapObject.setZoom(15)
          this.$refs.map.panTo(position)
        }
      }
      this.$refs.map.panBy(243, 0)  // shift EventBoard寬度的1/2
    },
    panToFocusEvent() {
      if (this.focusIndex === -1 || !this.eventList[this.focusIndex]) return
      let position = {
        lat: this.eventList[this.focusIndex].latitude,
        lng: this.eventList[this.focusIndex].longitude
      }
      this.$refs.map.panTo(position)
      this.$refs.map.panBy(243, 0)  // shift EventBoard寬度的1/2
    },
    onMouseOver(index) {
      this.hoverIndex = index
    },
    onMouseOut() {
      this.hoverIndex = -1
    }
  }
}
</script>

<style>
.history-marker-label {
  color: #ffffff;
  font-size: 20px;
  font-weight: 300;
  line-height: 28px;
  padding: 12px 8px 12px;
  border-radius: 8px;
  transform: translateX(-50%) translateY(-68px);
  opacity: 0.9;
}
</style>

<style scoped>
.wrap-gmap-mark-label {
  width: 100%;
  height: 100%;
}

.event-map-google-map {
  display: flex;
  background: #FFC600;
  border-radius: 20px;
  padding: 4px 12px 4px 4px;
  margin-bottom: 10px;
}

.event-map-google-map .info {
  display: flex;
  align-items: center;
}

.event-map-content {
  position: relative;
  background:#282942; 
  padding: 8px 12px; 
  border-radius: 8px;
}

.event-map-content::after {
  content: '';
  position: absolute;
  left: 48%;
  bottom: -11px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 12px 7px 0 7px;
  border-color: #282942 transparent transparent transparent;
}

.event-map-content .info {
  display: flex;
  align-items: center;
}

.event-map-colck-icon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.event-map-gps-icon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.event-map-colck-icon img,
.event-map-gps-icon img
 {
  width: 100%;
  height: 100%;
}
</style>